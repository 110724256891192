import axios from "axios";

const api = axios.create({
  // baseURL: "http://localhost:3003/api/help",
  baseURL: "https://api.hs-software.dev.br/api/help",
  headers: {
    'Content-Type': 'application/json',
  }
});
api.interceptors.response.use(config => {
  return config;
}, error => {
  if (error.response.status === 401) {
    window.location.href = "/login";
  }
  return Promise.reject(error);
})

export { api };

export const createSession = async (dados) => {
  const body = { filial: dados.filial };
  return api.post('/login', body, { auth: { username: dados.user, password: dados.password } });
}
