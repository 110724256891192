import React from "react";
import Modulo from "../../components/modulos";
import Titulobar from "../../components/TituloBar";
import { useGetContatosAll } from "../../services/Contatos.js";
import { useGetRetornosAll } from "../../services/Retornos.js";
import { useGetServicosAll } from "../../services/Servicos.js";
import { useGetModulosAll } from "../../services/Modulos.js";
import { useEffect } from "react";
import { AuthContext } from "../../context/Auth.jsx";
export default function Home() {

   const [servidor, setServidor] = React.useState();

   const retornos = useGetRetornosAll();
   const contatos = useGetContatosAll();
   const servicos = useGetServicosAll();
   const modulos = useGetModulosAll();

   useEffect(() => {
      if (servicos && !servidor) {
         setServidor(servicos[0]);
      }
   }, [servicos, servidor]);

   const { admin } = React.useContext(AuthContext);

   return (
      <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
         <Titulobar />
         <div style={{ flexDirection: "row", display: "flex", flex: 1, maxHeight: "calc(100vh - 60px)", backgroundColor: "#f6f5f5", }}>
            <div style={{ flex: 1, overflowY: "scroll", maxHeight: "100%", margin: "auto", minHeight: "100%", maxWidth: "60%", backgroundColor: "#fff", padding: "2em" }}>
               <div style={{ marginBottom: "2em", display: "flex", flexDirection: "row" }}>
                  <div style={{ flex: 3 }}>
                     <div className="mb-2">
                        {
                           servicos ? <h3>Servidores:</h3> : <></>
                        }
                        {
                           servicos?.map((ser, id) => {
                              return <div className="form-check" key={id}>
                                 <input className="form-check-input" type="radio" name="servidor" id={"server" + id} checked={servidor === ser} onChange={() => setServidor(ser)} />
                                 <label className="form-check-label" htmlFor={"server" + id}>
                                    {admin ? ser.id + " - " : ""} {ser.name}: {ser.url}
                                 </label>
                              </div>
                           })
                        }
                     </div>
                     {
                        contatos ? <h3>Contatos: </h3> : <></>
                     }
                     <ul style={{ listStyleType: "circle" }}>
                        {
                           contatos?.map(contato => <li key={contato.id}>{contato.contato}</li>)
                        }
                     </ul>
                  </div>

                  <div style={{ flex: 2 }}>
                     {retornos ? <h3>Códigos de Retornos:</h3> : ""}
                     <ul>
                        {
                           retornos?.map(retorno => {
                              return <li key={retorno.id}>{retorno.retorno}</li>;
                           })
                        }
                     </ul>
                  </div>
               </div>
               {
                  modulos?.map((mod) => <Modulo servidor={servidor} modulo={mod} key={mod.id} />)
               }
            </div>
         </div >
      </div >
   );
}