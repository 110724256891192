import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, createSession } from '../services/api';
import jwt_decode from "jwt-decode";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

   const navigate = useNavigate();

   const [token, setToken] = useState(localStorage.getItem('token'));

   const [admin, setAdmin] = useState(() => {
      if (!token) {
         return false;
      }

      let decoded = jwt_decode(token);

      return decoded.admin;

   });

   const validarToken = () => {
      if (!token) {
         return false;
      }

      api.defaults.headers.Authorization = `Bearer ${token}`;

      let decoded = jwt_decode(token);

      if (decoded.admin !== admin) {
         setAdmin(!!decoded.admin);
      }

      if (!decoded.exp) {
         return false;
      }

      if (new Date(decoded.exp * 1000) > new Date()) {
         return true;
      }
      return false;

   }

   const login = async (auth) => {
      try {
         const { status, data } = await createSession(auth);

         const tokenSession = data.token;

         if (status === 200) {
            localStorage.setItem('token', tokenSession);
            setToken(tokenSession);

            api.defaults.headers.Authorization = `Bearer ${tokenSession}`;

            return true;
         } else {
            return false;
         }
      } catch (err) {
         return { status: false, msg: err?.response?.data.msg };
      }
   };

   const logout = () => {
      localStorage.removeItem('token');
      api.defaults.headers.Authorization = null;
      setToken(null);
      navigate('/login');
   }

   return (
      <AuthContext.Provider value={{ authenticated: validarToken(), token, admin, setToken, login, logout }}>
         {children}
      </AuthContext.Provider>
   );

}