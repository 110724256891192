import React from "react";
import { AuthContext } from "../../context/Auth.jsx";
import TabHeader from "../tabheader";


export default function Endpoint(props) {
   const { endpoint, servidor } = props;

   const [tab, setTab] = React.useState(() => {
      if (isValid(endpoint.regras)) {
         return idRegras
      } else if (isValid(endpoint.headers)) {
         return idHeaderJson;
      } else if (isValid(endpoint.queryparam)) {
         return idQueryParam;
      } else if (isValid(endpoint.sendjson)) {
         return idSendjson;
      } else if (isValid(endpoint.responsejson)) {
         return idResponseJson;
      } else {
         return 0;
      }
   });

   const idAccordion = "-" + endpoint.id_modulo + endpoint.id;

   const { admin } = React.useContext(AuthContext);

   return (
      <div className="accordion-item">
         <h2 className="accordion-header" id={"panelsStayOpen-" + idAccordion}>
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
               data-bs-target={"#panelsStayOpen-collapse" + idAccordion} aria-expanded="false"
               aria-controls={"panelsStayOpen-collapse" + idAccordion}>
               <span style={{ color: "black", fontWeight: "600", "textTransform": "uppercase" }}><span className={"badge " + getMetodo(endpoint.metodo)} style={{ marginRight: 1 }}>{endpoint.metodo}</span>{admin ? " " + endpoint.id + " - " : ""}{endpoint.name}</span>
            </button>
         </h2>
         <div id={"panelsStayOpen-collapse" + idAccordion} className="accordion-collapse collapse"
            aria-labelledby={"panelsStayOpen-" + idAccordion}>
            <div className="accordion-body">
               <h6>{endpoint.descricao}</h6>
               <br />
               <h6><strong>URL: </strong><a href={servidor.url + endpoint.path} target="_black">{servidor.url + endpoint.path}</a></h6>
               {admin ? <h6><strong>Programa: </strong>{endpoint.programa}</h6> : ""}
               {tab !== 0 ? <>
                  <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">

                     <TabHeader active={tab === idHeaderJson} setActive={() => setTab(idHeaderJson)}
                        visible={isValid(endpoint.headers)} titulo="Headers" />

                     <TabHeader active={tab === idQueryParam} setActive={() => setTab(idQueryParam)}
                        visible={isValid(endpoint.queryparams)} titulo="QueryParam" />

                     <TabHeader active={tab === idSendjson} setActive={() => setTab(idSendjson)}
                        visible={isValid(endpoint.sendjson)} titulo="Request JSON" />

                     <TabHeader active={tab === idResponseJson} setActive={() => setTab(idResponseJson)}
                        visible={isValid(endpoint.responsejson)} titulo="Response JSON" />

                  </ul>

                  <div className="tab-content">
                     <div className={"tab-pane fade " + (tab === idHeaderJson ? " show active" : "")} role="tabpanel">
                        <pre style={{ backgroundColor: "#f6f6f6", border: "1px solid #828080" }}>
                           <code>
                              {JSON.stringify(endpoint["headers"], null, 4)}
                           </code>
                        </pre>
                     </div>

                     <div className={"tab-pane fade " + (tab === idQueryParam ? " show active" : "")} role="tabpanel">
                        {
                           endpoint.queryparams ? <div style={{ marginBottom: 15 }}>
                              <h5>Quey:</h5>
                              {Object.keys(endpoint.queryparams).map((key, id) => {
                                 return (<p key={id} style={{ marginLeft: 20 }}><strong>{key}</strong> - {endpoint.queryparams[key]}</p>);
                              })}
                           </div> : <></>
                        }
                     </div>

                     <div className={"tab-pane fade " + (tab === idSendjson ? " show active" : "")} role="tabpanel">

                        {
                           endpoint.validacoes ? <div style={{ marginBottom: 15 }}>
                              <h5>Validações:</h5>
                              {endpoint.validacoes.map((val, id) => (<p key={id} style={{ marginLeft: 20 }}><strong>{val.campo}</strong> - {val.validacao}</p>))}
                           </div> : <></>
                        }

                        <h5>Json:</h5>
                        <pre style={{ backgroundColor: "#f6f6f6", border: "1px solid #828080" }}>
                           <code>
                              {JSON.stringify(endpoint["sendjson"], null, 4)}
                           </code>
                        </pre>
                     </div>
                     <div className={"tab-pane fade " + (tab === idResponseJson ? " show active" : "")} role="tabpanel">
                        {
                           endpoint.informacoes ? <div style={{ marginBottom: 15 }}>
                              <h5>Info:</h5>
                              {Object.keys(endpoint.informacoes).map((key, id) => {
                                 return (<p key={id} style={{ marginLeft: 20 }}><strong>{key}</strong> - {endpoint.informacoes[key]}</p>);
                              })}
                           </div> : <></>
                        }
                        {
                           endpoint.responsejson ? <><h6>Código de sucesso: {endpoint.codesucess}</h6>
                              <pre style={{ backgroundColor: "#f6f6f6", border: "1px solid #828080" }}>
                                 <code>
                                    {JSON.stringify(endpoint["responsejson"], null, 4)}
                                 </code>
                              </pre>
                           </> : <></>
                        }
                        {
                           endpoint.response_errojson ? <>
                              <h6>Retorno em caso de erro:</h6>
                              <pre style={{ backgroundColor: "#f6f6f6", border: "1px solid #828080" }}>
                                 <code>
                                    {JSON.stringify(endpoint["response_errojson"], null, 4)}
                                 </code>
                              </pre>
                           </> : <></>
                        }

                     </div>
                  </div>
               </> : <></>
               }
            </div>
         </div>
      </div >
   );
}

const getMetodo = (metodo) => {
   switch (metodo) {
      case 'GET':
         return "bg-primary"
      case 'POST':
         return "bg-success"
      case 'PUT':
         return "bg-secondary"
      case 'DELETE':
         return "bg-danger"
      default:
         break;
   }
}

const isValid = (json) => {
   if (json === undefined || json == null) {
      return false;
   }
   return true;
}

const idRegras = 1;
const idHeaderJson = 2;
const idQueryParam = 3;
const idSendjson = 4;
const idResponseJson = 5;