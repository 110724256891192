import React from "react";
import { AuthContext } from "../../context/Auth.jsx";
import { useGetEndpoint } from "../../services/Endpoint.js";
import Endpoint from "../endpoint"
export default function Modulo(props) {
   const { servidor, modulo } = props;

   const dados = useGetEndpoint(modulo.id);

   const { admin } = React.useContext(AuthContext);

   if (dados !== undefined && dados.length) {
      return (
         <div className="accordion">
            <div className="accordion-item">
               <h2 className="accordion-header" id={"panelsStayOpen-heading" + modulo.titulo}>
                  <button className="accordion-button"
                     type="button"
                     data-bs-toggle="collapse"
                     data-bs-target={"#panelsStayOpen-collapse" + modulo.titulo}
                     aria-expanded="true"
                     aria-controls={"panelsStayOpen-collapse" + modulo.titulo}
                     style={{ color: "black" }}>
                     <h5><strong>{admin ? modulo.id + " - ": ""}{modulo.titulo}</strong></h5>
                  </button>
               </h2>
               <div id={"panelsStayOpen-collapse" + modulo.titulo} className="accordion-collapse collapse show" aria-labelledby={"panelsStayOpen-heading" + modulo.titulo}>
                  <div className="accordion-body" style={{ padding: "0.5em" }}>
                     <div className="accordion">

                        {
                           dados.map((end) => {
                              return <Endpoint endpoint={end} servidor={servidor} key={end.id} />
                           })
                        }

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   } else {
      return <></>;
   }
}