import React from "react";

import "./styles.css";

export default function Titulobar() {
   return (
      <nav className="navbar navbar-dark bg-dark" style={{height: 60}}>
         <div className="container-fluid">
            <h3 className="tituloBar">Documentação API</h3>
         </div>
      </nav>
   );
}