import useSWR from "swr";
import { api } from "./api";

const baseUrl = "/endpoints";

const fetcherGetAll = (params) => api.get(`${baseUrl}`, { params }).then(resp => resp.data);

const useGetEndpoint = (id_modulo) => {
   const { data } = useSWR({ url: `${api.defaults.baseURL}${baseUrl}`, args: { moduloId: id_modulo } }, () => fetcherGetAll({ moduloId: id_modulo }));
   return data;
}


export { useGetEndpoint };