import useSWR from "swr";
import { api } from "./api";

const baseUrl = "/contatos";

const fetcherGetAll = () => api.get(`${baseUrl}`).then(resp => resp.data);

const useGetContatosAll = () => {
   const { data } = useSWR(`${api.defaults.baseURL}${baseUrl}`, () => fetcherGetAll());

   return data;
}


export { useGetContatosAll };