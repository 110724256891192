import useSWR from "swr";
import { api } from "./api";

const baseUrl = "/servicos";

const fetcherGetAll = () => api.get(`${baseUrl}`).then(resp => resp.data);

const useGetServicosAll = () => {
   const { data, error } = useSWR(`${api.defaults.baseURL}${baseUrl}`, () => fetcherGetAll());

   return data;
}


export { useGetServicosAll };