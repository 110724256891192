import React from "react";
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { AuthContext } from '../../context/Auth';
import { toast } from 'react-toastify';

export default function Login() {
   const { register, handleSubmit } = useForm();

   const navigate = useNavigate();

   const [loading, setLoading] = React.useState(false);

   const { login } = React.useContext(AuthContext);

   const buttonSubmit = async (auth) => {
      setLoading(true);
      const data = await login(auth);
      if (data === true) {
         navigate("/");
      } else {
         toast.error(data.msg, { autoClose: 3000 });
      }
      setLoading(false);
   }

   return (
      <div style={{ display: 'flex', minHeight: "100vh", backgroundColor: "rgb(245, 245, 245)", justifyContent: "center", alignItems: "center" }}>
         <div style={{ backgroundColor: "white", width: "25em", height: "38em", borderRadius: "20px", boxShadow: "-35px 32px 14px rgba(29, 140, 231, 0.45)", padding: "2em", paddingTop: "5em", display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <div style={{ textAlign: "center" }}>
               <img src="./img/Logo-HS.png" alt="Logo da empresa" style={{ width: "14em", marginRight: "1em" }} />
            </div>
            <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
               <form onSubmit={handleSubmit(buttonSubmit)}>
                  <div className="form-floating mb-3">
                     <input type="text" className="form-control" placeholder="Usuário" {...register("user", { required: true })} />
                     <label htmlFor="floatingInput">Usuário</label>
                  </div>
                  <div className="form-floating mb-3">
                     <input type="password" className="form-control" placeholder="Senha" {...register("password", { required: true })} />
                     <label htmlFor="floatingPassword">Senha</label>
                  </div>
                  <div className="form-floating w-100" style={{ textAlign: "center" }}>
                     <button type="submit" className="btn btn-primary" disabled={loading} style={{ padding: "0.6em", width: '6em', fontWeight: "bold" }}>Login</button>
                  </div>
               </form>
            </div>
         </div>
      </div>
   );
}