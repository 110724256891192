import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './pages/home';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles.css";

import 'react-toastify/dist/ReactToastify.min.css';

import { AuthContext, AuthProvider } from './context/Auth';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/login';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root'));


const PrivateRoute = ({ children }) => {
  const { authenticated } = React.useContext(AuthContext);
  return authenticated ? children : <Navigate to="/login" />;
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme='dark'
      />
      <AuthProvider>
        <Routes>
          <Route path='/' element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          } />

          <Route path='/login' element={<Login />} />

          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

